
import { defineComponent } from "vue";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";
import Bus from "@/common/ts/Bus";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-account",
  components: {
    Dropdown3
  },
  data() {
    return {
      user: {}
    };
  },
  methods: {
    getUserDetail() {
      ApiService.post("MemberProfile/ProfileDetail", {} as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.user = data.data;
            store.dispatch(Actions.UPDATE_USER, this.user);
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    handleAvatarChange(e) {
      if (!e.target.files[0].size) {
        return;
      }
      const imgFile = e.target.files[0];
      if (imgFile.type.indexOf("image") != 0) {
        Toast.warning("文件类型仅为图片");
        return;
      }
      const param = new FormData();
      param.append("file", imgFile);
      ApiService.post("MemberProfile/AvatarUpload", param as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.getUserDetail();
            Toast.success(data.message);
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    submitAvatarRemove() {
      ApiService.post("MemberProfile/AvatarRemove", {} as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.getUserDetail();
            Toast.success(data.message);
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    handleAvatarRemove() {
      Toast.confirm("是否删除头像？", () => {
        this.submitAvatarRemove();
      });
    },
    initBus() {
      Bus.$on("getUserDetail", () => {
        this.getUserDetail();
      });
    }
  },
  beforeUnmount() {
    Bus.$off("getUserDetail");
  },
  mounted() {
    this.initBus();
    this.getUserDetail();
  }
});
